import {
    Button,
    ButtonGroup,
    Container,
    HStack,
    Heading,
    Input,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Backtest, BacktestingSetting } from '../../backtesting-common-frontend';
import { SelectionFilterMenu } from '../../backtesting-common-frontend/filters';
import { isBacktestLicense } from '../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities';
import { updateBacktest } from '../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { deleteMethod, editMethod } from '../../backtesting-common-frontend/http-utilities/http-utilities/methods/methods-backend.service';
import { StopLossTakeProfitHttp } from '../../backtesting-common-frontend/http-utilities/http-utilities/stoplosstakeprofits/stoplosstakeprofits.http';
import { updateStrategy } from '../../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy';
import { ImpreemMethodDTO } from '../../backtesting-common-frontend/methods';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { mergeNestedCapitalLetters, splitByCapitalLetter } from '../../backtesting-common-frontend/shared/utilites/string.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { TimeSeriesResultsManager } from '../../managers/time-series/time-series-manager';
import { setLoading, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';
import { ExplainerInfo } from '../shared/explainer/info';
import { ColumnDTO, RowTableDTO } from '../table';
import TableComponent from '../table/table';

export default function StrategyTable() {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const stopLossTakeProfits = useSelector(
        (state: AppState) => state.stopLossTakeProfits.stopLossTakeProfits
    );
    const { onOpen, onClose, isOpen } = useDisclosure();
    const test = useSelector((state: AppState) => state.tests.test);
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ backtest, setBacktest ] = useState<Backtest | null>(null);

    const [ allSelectionFilters, setAllSelectionFilters ] = useState<SelectionFilterMenu[]>([]);
    const [ allTheoryMethods, setAllTheoryMethods ] = useState<ImpreemMethodDTO[]>([]);
    const [ allAiNewsMethods, setAllAiNewsMethods ] = useState<ImpreemMethodDTO[]>([]);   
    const [ allSelectionCriteriaMethods, setAllSelectionCriteriaMethods ] = useState<ImpreemMethodDTO[]>([]);
    const [ name, setName ] = useState<string>('');

    const [ activePopover, setActivePopover ] = useState(null);

    const handleOpen = (popoverId) => {
        setActivePopover(popoverId);
        onOpen();
    };

    useEffect(() => {
        const s = cloneDeep(currentReduxStrategy);
        if(s){
            setCurrentStrategy(s);
            const methods = s.intersection;
            const filters = s.selectionFilters;
            setAllTheoryMethods(methods.filter(e => e.methodKey === "Theory"));
            setAllSelectionCriteriaMethods(methods.filter(e => e.methodKey === "SelectionCriteria"));
            setAllAiNewsMethods(methods.filter(e => e.methodKey === "AiNews"));
            setAllSelectionFilters(filters);
        }
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        setBacktest(cloneDeep(backtestCtx));
    }, [ backtestCtx ]);

    const selectionCriteriaRows: RowTableDTO[] = [];
    for(let i = 0; i < allSelectionCriteriaMethods.length; i++){
        if(!backtest || !currentTest || !currentStrategy){
            continue;
        }
        getMethodRow(selectionCriteriaRows, allSelectionCriteriaMethods, i);
    }

    const theoriesRow: RowTableDTO[] = [];
    for(let i = 0; i < allTheoryMethods.length; i++){
        if(!backtest || !currentTest || !currentStrategy){
            continue;
        }
        getMethodRow(theoriesRow, allTheoryMethods, i, true);
    }

    const aiNewsRows: RowTableDTO[] = [];
    for(let i = 0; i < allAiNewsMethods.length; i++){
        if(!backtest || !currentTest || !currentStrategy){
            continue;
        }
        getMethodRow(aiNewsRows, allAiNewsMethods, i);
    }

    const selectionFiltersRows: RowTableDTO[] = [];
    for(let i = 0; i < allSelectionFilters.length; i++){
        if(!backtest || !currentTest || !currentStrategy){
            continue;
        }
        selectionFiltersRows.push({
            columns: [
                {
                    title: i === 0 ? "Name" : null,
                    value: <>
                        = {allSelectionFilters[i].display}
                    </>,
                },
                {
                    title: i === 0 ? "Remove" : null,
                    value: <Button size="xs" colorScheme="red" onClick={async() => {
                        dispatch(setLoading(true));
                        const item = currentStrategy.selectionFilters[i];
                        currentStrategy.selectionFilters.splice(i, 1);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if(TimeSeriesResultsManager.isTimeSeries(item) || backtest.computeReturns.some(x => x.transformationKey === (item as any)?.transformationKey)){
                            backtest.computeReturns = backtest.computeReturns.filter(e => e.transformationKey !== item.transformationKey);
                            await updateBacktest(backtest);
                        }
                        updateStrategy(currentStrategy)
                            .then(() => {
                                const status = new StatusDisplayDTO("Deleted", "success");
                                dispatch(updateMessage(status));
                            })
                            .finally(() => {
                                dispatch(setLoading(false));
                            });
                    }
                    }>Remove</Button>,
                },
            ],
        });
    }

    const computeReturns: RowTableDTO[] = [];
    if(backtest && backtest?.computeReturns?.length > 0){
        const columns: ColumnDTO[] = [];
        for (let i = 0; i < backtest.computeReturns.length; i++) {
            const row = backtest.computeReturns[i];
            columns.push({ title: "Name", value: mergeNestedCapitalLetters(splitByCapitalLetter(row.display)) });
            columns.push({ title: "Remove", value: <FaTrash onClick={() => {
                const b = cloneDeep(backtest);
                b.computeReturns = b.computeReturns.filter(x => x != null && JSON.stringify(x) !== JSON.stringify(row));
                dispatch(setLoading(true));
                updateBacktest(b).finally(() => {
                    dispatch(setLoading(false));
                });
            }} /> });
        }
        for (let i = 0; i < columns.length; i++) {
            if (i % 2 === 0) {
                computeReturns.push({ columns: [ columns[i], columns[i + 1] ] });
            }
        }
    }

    selectionFiltersRows.push(...computeReturns);

    const noSelectionFilterAndNoMethods = selectionFiltersRows.length === 0 && theoriesRow.length === 0 && selectionCriteriaRows.length === 0;

    const stopLossTakeProfitRows: RowTableDTO[] = [];
    for (let i = 0; i < stopLossTakeProfits.length; i++) {
        stopLossTakeProfitRows.push({
            columns: [
                {
                    title: i === 0 ? "Stop loss" : null,
                    value: <>{stopLossTakeProfits[i].stopLossProcentageChange}%</>,
                },
                {
                    title: i === 0 ? "Take profit" : null,
                    value: <>{stopLossTakeProfits[i].takeProfitProcentageChange}%</>,
                },
                {
                    title: i === 0 ? "Remove" : null,
                    value: <Button size="xs" colorScheme="red" onClick={() => {
                        dispatch(setLoading(true));
                        StopLossTakeProfitHttp.remove(stopLossTakeProfits[i], dispatch)
                            .then(() => {
                                const status = new StatusDisplayDTO("Deleted", "success");
                                dispatch(updateMessage(status));
                            })
                            .finally(() => {
                                dispatch(setLoading(false));
                            });
                    }}>Remove</Button>,
                },
            ],
        });
    }

    return (
        <Container width={'full'} p={3}>
            {noSelectionFilterAndNoMethods && isBacktestLicense() && <Heading size={'md'}>No methods or selection filters</Heading>}
            {selectionFiltersRows.length !== 0 && currentStrategy && <><HStack>
                <MathJax>
                    {`
                \\[
                 Sample (assets) = \\{a_1, a_2, \\ldots, a_i\\} =
                \\]
            `}
                </MathJax>
                <FaTrash cursor={'pointer'} color={'gray'} onClick={() => {
                    dispatch(setLoading(true));
                    currentStrategy.selectionFilters = [];
                    backtest.computeReturns = [];
                    updateBacktest(backtest).then(() => {
                        updateStrategy(currentStrategy).finally(() => {
                            dispatch(setLoading(false));
                        });
                    });
                }} />
            </HStack>
            <TableComponent rows={selectionFiltersRows} />
            </>}
            {theoriesRow.length !== 0 && currentStrategy && <><HStack>
                <Heading size={'md'}><MathJax>
                    {`
                \\[
                    Model: a_i
                \\]
            `}
                </MathJax></Heading>
                <FaTrash cursor={'pointer'} color={'gray'} onClick={() => {
                    dispatch(setLoading(true));
                    currentStrategy.intersection = [];
                    updateStrategy(currentStrategy).finally(() => {
                        dispatch(setLoading(false));
                    });
                }} />
            </HStack>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>
                                Dependent variable
                            </Th>
                            <Th>&nbsp;
                            </Th>
                            <Th>&nbsp;</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <MathJax>
                                    {`
                \\[
                    Y = R^{a_i}_{\\scriptsize\\substack{t+n}}
                \\]
            `}
                                </MathJax>
                            </Td>
                            <Td style={{ visibility: "hidden" }}>                                <MathJax>
                                {`
                \\[
                    Y: R^{a_i}_{\\scriptsize\\substack{t+n}}
                \\]
            `}
                            </MathJax>
                            </Td>
                            <Td style={{ visibility: "hidden" }}>                                <MathJax>
                                {`
                \\[
                    Y: R^{a_i}_{\\scriptsize\\substack{t+n}}
                \\]
            `}
                            </MathJax></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <TableComponent rows={theoriesRow} /></>}
            {selectionCriteriaRows.length !== 0 && currentStrategy && <><HStack>
                <Heading size={'md'}>Screening methods:</Heading>
                <FaTrash cursor={'pointer'} color={'gray'} onClick={() => {
                    dispatch(setLoading(true));
                    currentStrategy.intersection = [];
                    updateStrategy(currentStrategy).finally(() => {
                        dispatch(setLoading(false));
                    });
                }} />
            </HStack>
            <ExplainerInfo.Information title='Intersect or recrurrent screening methods'
                text={"All screening methods are intersected, meaning that a company selected from one method must be selected in all methods. If you want a sample from a method to be the sample for the next method, you can use a backtest as the sample."}
            />

            <TableComponent rows={selectionCriteriaRows} /></>}
            {aiNewsRows.length !== 0 && currentStrategy && <><HStack>
                <Heading size={'md'}>AI news searcher Analysis</Heading>
                <FaTrash cursor={'pointer'} color={'gray'} onClick={() => {
                    dispatch(setLoading(true));
                    currentStrategy.intersection = [];
                    updateStrategy(currentStrategy).finally(() => {
                        dispatch(setLoading(false));
                    });
                }} />
            </HStack>
            <TableComponent rows={aiNewsRows} /></>}
            {stopLossTakeProfitRows.length !== 0 && <><HStack>
                <Heading size={'md'}>Stop loss and take profit</Heading>
            </HStack>
            <TableComponent rows={stopLossTakeProfitRows} /></>}
        </Container>
    );

    function getMethodRow(row: RowTableDTO[], methods: ImpreemMethodDTO[], i: number, isTheory = false) {
        row.push({
            columns: [
                isTheory ? {
                    title: i === 0 ? "Explanatory variable" : null,
                    value: <>
                        <MathJax>
                            {`
                \\[
                    D${i + 1}_t: ${methods[i].display}
                \\]
            `}
                        </MathJax>
                    </>,
                } : null,
                {
                    title: i === 0 ? "Active" : null,
                    value: <>
                        <Button size="xs" colorScheme={methods[i].active ? "blue" : "gray"} onClick={() => {
                            methods[i].active = !methods[i].active;
                            dispatch(setLoading(true));
                            editMethod(methods[i])
                                .then(() => {
                                    const status = new StatusDisplayDTO("Method active changed", "success");
                                    dispatch(updateMessage(status));
                                })
                                .finally(() => {
                                    dispatch(setLoading(false));
                                });
                        }}>
                            {methods[i].active ? "Active" : "Inactive"}
                        </Button>
                    </>,
                },
                !isTheory ? {
                    title: i === 0 ? "Note (name)" : null,
                    value: <>
                        {methods[i].name}
                    </>,
                } : null,
                {
                    title: i === 0 ? "Time series" : null,
                    value: <>
                        {methods[i].timeseries.map(e => e.display).join(", ")}
                    </>,
                },
                {
                    title: i === 0 ? "Change Name" : null,
                    value: <Popover
                        isOpen={isOpen && activePopover === methods[i].methodKey + 'popover' + i.toString()}
                        onOpen={onOpen}
                        onClose={onClose}
                        placement='right'
                        closeOnBlur={false}
                        id={i + "changeName"}
                    >
                        <PopoverTrigger>
                            <Button 
                                colorScheme={'gray'} 
                                size="sm" 
                                onClick={() => handleOpen(methods[i].methodKey + 'popover' + i.toString())}
                                width={'full'}>Change name</Button>
                        </PopoverTrigger>
                        <PopoverContent p={5}>
                            <PopoverHeader fontWeight='semibold'>Change method name</PopoverHeader>
                            <PopoverBody>
                                <Input
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    } } />
                            </PopoverBody>
                            <PopoverFooter display='flex' justifyContent='flex-end'>
                                <ButtonGroup size='sm'>
                                    <Button variant='outline' onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme='blue' onClick={async() => {
                                        methods[i].name = name;
                                        dispatch(setLoading(true));
                                        await editMethod(methods[i])
                                            .then(() => {
                                                const status = new StatusDisplayDTO("Method name changed", "success");
                                                dispatch(updateMessage(status));
                                            })
                                            .finally(() => {
                                                dispatch(setLoading(false));
                                            });
                                        onClose();
                                    } }>
                                        Save
                                    </Button>
                                </ButtonGroup>
                            </PopoverFooter>
                        </PopoverContent>
                    </Popover>,
                },
                {
                    title: i === 0 ? "Remove" : null,
                    value: <Button size="xs" colorScheme="red" onClick={async() => {
                        dispatch(setLoading(true));
                        await deleteMethod(currentTest._id, currentStrategy._id, methods[i])
                            .then(() => {
                                const status = new StatusDisplayDTO("Deleted", "success");
                                dispatch(updateMessage(status));
                            })
                            .finally(() => {
                                dispatch(setLoading(false));
                            });
                    } }>Remove</Button>,
                },
                {
                    title: i === 0 ? "Order" : null, // Title for the first row, null for others
                    value: (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdArrowUpward onClick={() => {
                                const m = currentStrategy.intersection.splice(i, 1)[0];
                                currentStrategy.intersection.splice(i - 1, 0, m);
                                dispatch(setLoading(true));
                                updateStrategy(currentStrategy)
                                    .then(() => {
                                        const status = new StatusDisplayDTO("Method moved up", "success");
                                        dispatch(updateMessage(status));
                                    })
                                    .finally(() => {
                                        dispatch(setLoading(false));
                                    });
                            }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                            <MdArrowDownward onClick={() => {
                                const m = currentStrategy.intersection.splice(i, 1)[0];
                                currentStrategy.intersection.splice(i + 1, 0, m);
                                dispatch(setLoading(true));
                                updateStrategy(currentStrategy)
                                    .then(() => {
                                        const status = new StatusDisplayDTO("Method moved down", "success");
                                        dispatch(updateMessage(status));
                                    })
                                    .finally(() => {
                                        dispatch(setLoading(false));
                                    });
                            }} style={{ cursor: 'pointer' }} />
                        </div>
                    ),
                },
            ].filter(e => e != null),
        });
    }
}
