/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Container, FormControl, FormLabel, Heading, Input, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAndDispatchOrNull, getOriginalTimeSeries } from "../../action-creators/timeseries/time-series.action";
import { Backtest, GraphItem } from "../../backtesting-common-frontend";
import { getIndexesBySymbol } from "../../backtesting-common-frontend/http-utilities/http-utilities/time-series/time-series-backend.service";
import { TimeSeriesDTO, timeSeriesScaleType } from "../../backtesting-common-frontend/methods";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { capitalizeFirstLetter, createId } from "../../backtesting-common-frontend/shared/utilites/string.utilities";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { TimeSeriesHelperValue } from "../../backtesting-common-frontend/timeseries/timeseries-models";
import { TransformationDTO, TransformationTypeKey } from "../../backtesting-common-frontend/transformations/transformations-dtos";
import { TransformationService, timeSeriesNeedsComparision } from "../../backtesting-common-frontend/worker/transformations/transformation-service";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";
import { setLoading, updateMessage } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import { TransformationResults } from "../../store/transformations/transformations";
import { Tab } from "../tabs";
import { CategoryItem, SelectTimeSeries } from "../time-series/time-series-selector";
import TransformationExplainer from "./transform-explainer";

export default function HandleTransformationSettingsComponent() {
    const dispatch = useDispatch();
    const testCtx = useSelector(
        (state: AppState) => state.tests
    );
    const transformationCtx = useSelector(
        (state: AppState) => state.transformations
    );
    const currentBacktestCtx = useSelector(
        (state: AppState) => state.backtests
    );
    const graphItemsCtx = useSelector(
        (state: AppState) => state.graphItems.graphItems
    );
    const editActiveCtx = useSelector(
        (state: AppState) => state.graphItems.editActive
    );
    const menuCategory = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const [ currentBacktest, setCurrentBacktest ] = useState<Backtest | null | undefined>(null);
    const [ originalTimeseries, setOriginalTimeseries ] = useState<TimeSeriesDTO | null>(null);
    const [ currentTimeseries, setCurrentTimeseries ] = useState<TimeSeriesDTO | null>(null);
    const [ currentTransformation, setCurrentTransformation ] = useState<TransformationDTO | null>(null);
    const [ currentGroupId ] = useState<string | null>(null);
    const [ transformationService ] = useState<TransformationService>(new TransformationService());
    const [ timeSeriesResultsManager ] = useState<TimeSeriesResultsManager>(new TimeSeriesResultsManager());
    const [ extrasTimeSeriesSameVariable, setExtrasTimeSeries ] = useState<TimeSeriesDTO[] | null>([]);
    const [ graphItems, setGraphItems ] = useState<GraphItem[]>([]);
    const [ originalTransformation, setOriginalTransformation ] = useState<TransformationResults | null>(null);

    const [ comparisionSeries, setComparisionSeries ] = useState<TimeSeriesDTO | null>(null);

    const [ dickeyFullerTest, setDickeyFullerTest ] = useState<number | null>(null);
    //Modal
    const [ modalInfoVisibility, setModalInfoVisibility ] = useState<boolean>(false);
    const [ infoText, setInfoText ] = useState<string | null>(null);
    // Modal end

    const [ param1, setParam1 ] = useState<string | null>(null);
    const [ param2, setParam2 ] = useState<string | null>(null);
    const [ param3, setParam3 ] = useState<string | null>(null);
    const [ param4, setParam4 ] = useState<string | null>(null);
    const [ param1Name, setParam1Name ] = useState<string | null>(null);
    const [ param2Name, setParam2Name ] = useState<string | null>(null);
    const [ param3Name, setParam3Name ] = useState<string | null>(null);
    const [ param4Name, setParam4Name ] = useState<string | null>(null);

    // tabs
    const defaultTab = new Tab("None", 0, true);
    const theTabs = [
        defaultTab,
        new Tab("First", 1, false),
        new Tab("Second", 2, false),
        new Tab("Third", 3, false),
        new Tab("Fourth", 4, false),
    ];
    const [ currentTab, setCurrentTab ] = useState<Tab>(defaultTab);
    const [ tabs ] = useState<Tab[]>(theTabs);
    const [ categoryType, setCategoryType ] = useState<number[] | null>(null);
    const [ normalization, setNormalization ] = useState<boolean>(false);
    const [ standardization, setStandardization ] = useState<boolean>(false);
    const [ multiply, setMultiply ] = useState<number | null>(null);

    const defaultDisaggregationTab = new Tab("Previous value", 'previous-value', true);
    const disaggregationTab = [
        defaultDisaggregationTab,
        new Tab("Average growth", 'average', false),
    ];
    const [ currentDisTab, setCurrentDisTab ] = useState<Tab>(defaultDisaggregationTab);
    const [ disTabs ] = useState<Tab[]>(disaggregationTab);
    const [ disMethod, setDisAggMethod ] = useState<string>('previous-value');
    // end tabs

    React.useEffect(() => {
        if(transformationCtx){
            setOriginalTransformation(cloneDeep(transformationCtx));
        }
    }, [ transformationCtx ]);

    React.useEffect(() => {
        if(graphItemsCtx){
            const p = cloneDeep(graphItemsCtx);
            if(p){
                setGraphItems(p);
            }
        }
    }, [ graphItemsCtx ]);

    useEffect(() => {
        const getActiveMainGraphItem = currentBacktestCtx?.backtest ? timeSeriesResultsManager.getEditGraphItems(graphItemsCtx, editActiveCtx) : null;
        if(getActiveMainGraphItem){
            setCurrentTimeseries(getActiveMainGraphItem.timeSeries);
        }
        if (getActiveMainGraphItem != null && currentTransformation == null && originalTransformation) {
            setOriginalTimeseries(getActiveMainGraphItem.extras[0]);
            if (getActiveMainGraphItem?.timeSeries) {
                setNormalization(getActiveMainGraphItem?.timeSeries?.normalization === "normal");
                setStandardization(getActiveMainGraphItem?.timeSeries?.normalization === "std");
                setMultiply(getActiveMainGraphItem?.timeSeries?.multiply);
            }
            setCurrentTab(theTabs.find(t => t.Id === getActiveMainGraphItem[0]?.timeSeries?.categorizeType) ?? defaultTab);
            const timeseries = getActiveMainGraphItem.timeSeries;
            if (timeseries) {
                // find transformationKey in transformationCtx
                // get key from timeseries.tranformationKey first '-' before
                const key = timeseries.transformationKey.split('-')[0];
                if (key) {
                    const transformation = originalTransformation.transformations.find(t => t.key === key);
                    if (transformation && transformation.key !== "none") {
                        transformation.parameters = timeseries.parameters;
                        setCurrentTransformation(transformation);
                    }
                }
            }
        } else if (graphItemsCtx.length === 0) {
            setCurrentTransformation(null);
        }
        if (currentBacktestCtx?.backtest) {
            setCurrentBacktest(cloneDeep(currentBacktestCtx?.backtest));
        }
    }, [ ]);

    function handleNewTransformationSelect(currTransform: TransformationDTO) {
        setCategoryType(null);
        setNormalization(false);
        setCurrentTab(defaultTab);
        setDisAggMethod('previous-value');
        setDickeyFullerTest(null);
        setCurrentTransformation(currTransform);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSelect = async(
        normalizationPar?: timeSeriesScaleType,
        disMethodPar?: string) => {

        normalizationPar = normalizationPar !== undefined ? normalizationPar : normalization ? "normal" : standardization ? "std" : "none";

        if (currentTimeseries == null || testCtx?.test == null
            || currentBacktest == null) {
            return;
        }

        const currentGraphItemIsNone = currentTimeseries.transformations.length === 0;

        const getActiveMainGraphItem = graphItems.find(t => editActiveCtx === t._id);

        if (getActiveMainGraphItem == null) return;

        const originalSeriesFromGraph: TimeSeriesDTO | undefined | null = currentGraphItemIsNone ? await getOriginalTimeSeries(
            dispatch, 
            getActiveMainGraphItem, 
            currentBacktest, 
            graphItems,
            currentTimeseries, 
            timeSeriesResultsManager,
            true) : graphItemsCtx.find(x => x.timeSeries.transformationKey === currentTimeseries.transformationKey)?.timeSeries;

        if (originalSeriesFromGraph == null) {
            const status = new StatusDisplayDTO("Could not find orginal time series", "error");
            dispatch(updateMessage(status));
            return;
        }

        const key = (currentTransformation?.key ?? "none") as TransformationTypeKey;

        // check if transformationKey needs comparision time series
        let extrasTimeSeriesHelper: TimeSeriesDTO[] | null = [];
        if(!comparisionSeries){
            const comparisionSymbols = currentBacktest.comparisonIndex;
            const allSymbolsStored = extrasTimeSeriesSameVariable?.map(x => {
                const key = x.timeSeriesKey.split('-')[1];
                return key;
            });
            const isNotInComparision = allSymbolsStored ? comparisionSymbols.some(t => allSymbolsStored.includes(t) === false) : null;
            if (timeSeriesNeedsComparision.includes(key) && isNotInComparision) {
                extrasTimeSeriesHelper = await getIndexesBySymbol(comparisionSymbols);
                setExtrasTimeSeries(extrasTimeSeriesHelper);
            }
        }

        const extraTs = comparisionSeries ? graphItemsCtx.find(x => x.timeSeries.transformationKey === comparisionSeries.transformationKey && 
            (TimeSeriesResultsManager.isCompanyTimeSeries(comparisionSeries) ? x.timeSeries.ID === comparisionSeries.ID : true))?.timeSeries ?? await getOriginalTimeSeries(
            dispatch, 
            getActiveMainGraphItem, 
            currentBacktest, 
            graphItems,
            comparisionSeries, 
            timeSeriesResultsManager,
            true) : null;

        setDickeyFullerTest(null);
        dispatch(setLoading(true));
        setTimeout(async() => {
            try {
                if (originalSeriesFromGraph == null || currentBacktest == null) return;
                let display = "";
                const parameters = cloneDeep(currentTransformation?.parameters) ?? {};
                for (const key in parameters) {
                    if(param1Name === key && param1){
                        parameters[key] = +param1;
                        display += key + ": " + parameters[key] + ", ";
                    }
                    if(param2Name === key && param2){
                        parameters[key] = +param2;
                        display += key + ": " + parameters[key] + ", ";
                    }
                    if(param3Name === key && param3){
                        parameters[key] = +param3;
                        display += key + ": " + parameters[key] + ", ";
                    }
                    if(param4Name === key && param4){
                        parameters[key] = +param4;
                        display += key + ": " + parameters[key] + ", ";
                    }
                }
                const transformationKey = key + '-' + originalSeriesFromGraph?.transformationKey.split('-').slice(1).join('-');
                const graphValues = cloneDeep(originalSeriesFromGraph.graphValue.map(x => {
                    return { ...x, symbol: "test", transformationKey: transformationKey, parameters: parameters };
                })) as TimeSeriesHelperValue[];
                const copyTimeseries = cloneDeep(originalSeriesFromGraph) as TimeSeriesDTO;
                copyTimeseries.display = '';
                copyTimeseries.parameters = parameters;
                copyTimeseries.multiply = multiply;
                copyTimeseries.transformations = [ key ];
                // not possible right now.
                copyTimeseries.categorizeType = null;
                copyTimeseries.normalization = normalizationPar ?? "none";
                display = display + "Normalization: " + copyTimeseries.normalization;
                copyTimeseries.disaggregationMethod = (disMethodPar ?? currentTimeseries.disaggregationMethod) ?? 'previous-value';
                copyTimeseries.transformationKey = transformationKey;
                const res = await transformationService.getTransformationSeries(
                    copyTimeseries,
                    key, 
                    graphValues, 
                    currentBacktest.periodicity, 
                    { extraTimeSeries: comparisionSeries ? [ cloneDeep(extraTs) ] : extrasTimeSeriesHelper && extrasTimeSeriesHelper?.length > 0 ? extrasTimeSeriesHelper : extrasTimeSeriesSameVariable });
                copyTimeseries.transformationKey = transformationKey;
                copyTimeseries.timeSeriesKey = originalSeriesFromGraph.timeSeriesKey;
                let endDateFromOriginal = new Date();
                // do a reverse loop of originalSeriesFromGraph.graphValue and find the first value that is not null
                for (let i = originalSeriesFromGraph.graphValue.length - 1; i >= 0; i--) {
                    const element = originalSeriesFromGraph.graphValue[i];
                    if (element.value != null) {
                        endDateFromOriginal = new Date(element.date);
                        break;
                    }
                }
                copyTimeseries.graphValue = res.map(e => {
                    const x = new TimeSeriesHelperValue();
                    x.value = e.value;
                    x.date = e.date;
                    return x;
                }).filter(x => new Date(x.date).getTime() < endDateFromOriginal.getTime());

                const newPreviewResults = new GraphItem();
                newPreviewResults.groupId = getActiveMainGraphItem.groupId ?? createId();
                newPreviewResults.tab = null;
                newPreviewResults.timeSeries = copyTimeseries;
                newPreviewResults.timeSeries.transformationKey = copyTimeseries.transformationKey;
                newPreviewResults.timeSeries.transformations = [ ...(graphItemsCtx.find(x => x.timeSeries.transformationKey === currentTimeseries.transformationKey)?.timeSeries.transformations ?? []), key ];
                newPreviewResults.timeSeries.timeSeriesKey = copyTimeseries.timeSeriesKey;
                newPreviewResults.timeSeries.display = originalSeriesFromGraph.display + (currentTransformation?.display && key != "none" ? ' - Transformation: ' + currentTransformation.display : " ") + display;
                newPreviewResults.timeSeries.frontendId = null;
                newPreviewResults.timeSeries.parameters = { ...originalSeriesFromGraph.parameters, ...parameters };
                newPreviewResults.timeSeries.timeseries = extraTs ? [ extraTs ] : [];
                const previousExtraTsExists = originalSeriesFromGraph.timeseries;
                newPreviewResults.timeSeries.timeseries = newPreviewResults.timeSeries.timeseries.concat(previousExtraTsExists ?? []);
                newPreviewResults.extras = [ originalSeriesFromGraph ];

                newPreviewResults.editActive = true;
                let newGraphItems = graphItems.filter(x => !x.editActive);
                newGraphItems = newGraphItems.map(e => {
                    e.editActive = false;
                    return e;
                });

                addAndDispatchOrNull(newPreviewResults, newGraphItems, dispatch);

            } catch (error) {
                console.log(error);
            } finally {
                dispatch(setLoading(false));
            }
        }, 1);
    };

    // useEffect(() => {
    //     setDickeyFullerTest(null);
    //     handleSelect([]);
    // }, [ currentTransformation, handleSelect ]);

    const handleParamChange = (paramName: number, value: string) => {
        switch (paramName) {
        case 1:
            setParam1(value);
            break;
        case 2:
            setParam2(value);
            break;
        case 3:
            setParam3(value);
            break;
        case 4:
            setParam4(value);
            break;
        default:
            break;
        }
        // const key = Object.keys(currentTransformation?.parameters ?? {})[paramName - 1];
        // const newParameters = cloneDeep(currentTransformation?.parameters);
        // newParameters[key] = +value.target.value;
        // setCurrentTransformation({ ...currentTransformation, parameters: newParameters });
    };

    useEffect(() => {
        if(currentTransformation?.parameters != null){
            const values = Object.entries(currentTransformation?.parameters);
            // check if values exists then assign to setParam
            if(values[0] && values[0][1] && typeof values[0][1] === "number"){
                setParam1(values[0][1].toString());
                setParam1Name(values[0][0]);
            }else{
                setParam1(null);
                setParam1Name(null);
            }
            if(values[1] && values[1][1] && typeof values[1][1] === "number"){
                setParam2(values[1][1].toString());
                setParam2Name(values[1][0]);
            }else{
                setParam2(null);
                setParam2Name(null);
            }
            if(values[2] && values[2][1] && typeof values[2][1] === "number"){
                setParam3(values[2][1].toString());
                setParam3Name(values[2][0]);
            }else{
                setParam3(null);
                setParam3Name(null);
            }
            if(values[3] && values[3][1] && typeof values[3][1] === "number"){
                setParam4(values[3][1].toString());
                setParam4Name(values[3][0]);
            }else{
                setParam4(null);
                setParam4Name(null);
            }
        }
    }, [ currentTransformation?.parameters ]);

    return (
        <Box paddingLeft="10px" paddingRight="10px" minH={'90vh'} position={'relative'}>
            <Heading size="md" color={useColorModeValue('black', 'white')} m={3}>Transformation settings</Heading>
            <Container
                minW={'full'}
                bg={useColorModeValue('white', 'whiteAlpha.100')}
                boxShadow={'xl'}
                rounded={'lg'}
                p={6}>
                {currentTimeseries == null && <Container
                    minW={'full'}
                    bg={'blue.100'}
                    boxShadow={'xl'}
                    rounded={'lg'}
                    p={6}>
                    <Heading size="md" color={'black'}>No time series selected</Heading>
                </Container>}
                <Select options={transformationCtx.transformations.map(e => {
                    return {
                        label: e.display,
                        value: e,
                    };
                })} placeholder='Choose a transformation' onChange={(value) => {
                    handleNewTransformationSelect(cloneDeep((value as any).value));
                } } />
            </Container>
            {currentTransformation != null && <>
                <div>
                    {param1 !== null && (
                        <FormControl mb={4}>
                            <FormLabel>{capitalizeFirstLetter(param1Name)}</FormLabel>
                            <Input type="text" value={param1} onChange={(e) => handleParamChange(1, e.target.value)} />
                        </FormControl>
                    )}
                    {param2 !== null && (
                        <FormControl mb={4}>
                            <FormLabel>{capitalizeFirstLetter(param2Name)}</FormLabel>
                            <Input type="text" value={param2} onChange={(e) => handleParamChange(2, e.target.value)} />
                        </FormControl>
                    )}
                    {param3 !== null && (
                        <FormControl mb={4}>
                            <FormLabel>{capitalizeFirstLetter(param3Name)}</FormLabel>
                            <Input type="text" value={param3} onChange={(e) => handleParamChange(3, e.target.value)} />
                        </FormControl>
                    )}
                    {param4 !== null && (
                        <FormControl mb={4}>
                            <FormLabel>{capitalizeFirstLetter(param4Name)}</FormLabel>
                            <Input type="text" value={param4} onChange={(e) => handleParamChange(4, e.target.value)} />
                        </FormControl>
                    )}
                </div>
            </>}
            <VStack spacing={4} align="stretch">
                <Text fontSize="lg">Select normalisation:</Text>
                <Select
                    options={[ {
                        label: "None",
                        value: "none",
                    }, {
                        label: "Normal",
                        value: "normal",
                    }, {
                        label: "Standard",
                        value: "std",
                    } ]}
                    placeholder="Select normalisation"
                    onChange={(value) => {
                        setNormalization((value as any).value === "normal");
                        setStandardization((value as any).value === "std");
                    }}
                />
                <TransformationExplainer currentTransformation={currentTransformation} />
            </VStack>
            {(currentTransformation?.key) === "beta" && <>
                <Heading size="md" color={'white'} m={3}>Select comparision time series</Heading>
                <SelectTimeSeries 
                    data={graphItems.filter(ts => !ts.timeSeries.transformationKey.includes("beta")).map(ts => ts.timeSeries).map(ts => {
                        const categoryFake = new CategoryItem();
                        categoryFake.category = ts.display;
                        categoryFake.categoryTitle = ts.display;
                        categoryFake.items = [ ts ];
                        return categoryFake;
                    })}
                    onHandleSelectionChange={(selectedItem) => {
                        if(TimeSeriesResultsManager.isTimeSeries(selectedItem)){
                            setComparisionSeries(selectedItem);
                        }else{
                            dispatch(updateMessage(new StatusDisplayDTO("Could not add time series - it is not a valid time series", "error")));   
                        }
                    }}
                />
            </>}
            <Container position={'absolute'} bottom={0} right={0} p={3}>
                <Button alignSelf="flex-end" mt={4} colorScheme="teal" width={'full'} onClick={() => {
                    handleSelect(normalization ? "normal" : standardization ? "std" : "none");
                }}>
            Transform
                </Button>
            </Container>
        </Box>
    );
}

