import { Box, Container, Divider, Flex, FlexProps, Heading, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { CiCalendarDate, CiCircleList } from "react-icons/ci";
import { FaArrowUp, FaDotCircle, FaExclamation, FaNewspaper, FaRegDotCircle, FaStop, FaUsers } from "react-icons/fa";
import { MdHistoryToggleOff } from "react-icons/md";
import { isBacktestLicense } from "../../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { MenuCategory } from "../../../backtesting-common-frontend/menu/dtos/menu-dtos";
import Absolute from "../../absolute/absolute";
import AiNewsMethod from "../../ai-news/ai-news";
import Growth from "../../growth/growth";
import LazyLoading from "../../lazy-loading/lazy-loding";
import Outliers from "../../outliers/outliers";
import { Relative } from "../../relative/relative";
import { DateSelector } from "../../select-dates/dates-select";
import { HistoricalEvents } from "../../select-dates/historical-events";
import SelectionFilters from "../../selection-filters/selection-filters";
import StopLossTakeProfit from "../../stop-loss-take-profit/stop-loss-take-profit";
import ToppLists from "../../topp-lists/topp-lists";

type SidebarItemType = {
    key: string;
    icon: React.ReactNode;
    width: number;
    preventHover: boolean;
    onClick: (type: SidebarType) => void;
    text: SidebarType;
  };
  
export type SidebarType =
    | 'Sample'
    | 'Cointegration test'
    | 'Screening Analysis'
    | 'Growth'
    | 'Cointegration methods'
    | 'Years/Months/Days'
    | 'Historical events'
    | 'Absolute'
    | 'Outliers'
    | 'AI news searcher'
    | 'Stop loss and take profit';

interface NavItemProps extends FlexProps {
    icon: JSX.Element;
    children: React.ReactNode;
    preventHover?: boolean;
  }

export const NavItem = ({ icon, children, width, preventHover, ...rest }: NavItemProps) => {
    const [ showChildren, setShowChildren ] = React.useState(false);
    return (
        <>
            <Flex
                minW={'full'}
                p="4"
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                position={'relative'}
                onMouseLeave={() => {
                    setShowChildren(false);
                }}
                onMouseEnter={() => {
                    setShowChildren(true);
                }}
                _hover={!preventHover ? {
                    bg: 'blue.400',
                    color: 'white',
                } : undefined}
                {...rest}>
                {icon && (
                    <div style={{ display: 'flex', justifyContent: 'center', minWidth: '100%', zIndex: 10 }}>
                        {icon}
                    </div>
                )}
                {showChildren && !preventHover && <Flex
                    bg={'blue.400'}
                    minW={'full'}
                    position={'absolute'}
                    height={'100%'}
                    paddingRight={4}
                    left={82}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={showChildren && !preventHover ? 'max-content' : 'max-content'}
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    {...rest}>
                    { children}
                </Flex>}
            </Flex>
        </>
    );
};
  
export function SidebarMenu({ handleSidebarClick, showSidebar, showThis }: {handleSidebarClick: (type: SidebarType) => void, showSidebar: SidebarType | null, showThis?: SidebarType[]}) {
    const selectColor = '#81badb';

    let sidebarContent: SidebarItemType[] = [
        isBacktestLicense() ? {
            key: 'selectioncriteria',
            icon: <FaUsers style={{ color: showSidebar === "Sample" ? selectColor : undefined }} size={35} />,
            width: 300,
            preventHover: showSidebar === 'Sample',
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Sample',
        } : null,
        isBacktestLicense() ? {
            key: 'topplists',
            icon: <CiCircleList style={{ color: showSidebar === 'Screening Analysis' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Screening Analysis',
            preventHover: showSidebar === 'Screening Analysis',
        } : null,
        {
            key: 'growthmethod',
            icon: <FaArrowUp style={{ color: showSidebar === 'Growth' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Growth',
            preventHover: showSidebar === 'Growth',
        },
        {
            key: 'relativmethod',
            icon: <FaRegDotCircle style={{ color: showSidebar === 'Cointegration methods' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Cointegration methods',
            preventHover: showSidebar === 'Cointegration methods',
        },
        {
            key: 'yearsmonthsmethod',
            icon: <CiCalendarDate style={{ color: showSidebar === 'Years/Months/Days' ? selectColor : undefined }} size={35} />,
            width: 310,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Years/Months/Days',
            preventHover: showSidebar === 'Years/Months/Days',
        },
        {
            key: 'historicaleventsmethod',
            icon: <MdHistoryToggleOff style={{ color: showSidebar === 'Historical events' ? selectColor : undefined }} size={35} />,
            width: 300,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Historical events',
            preventHover: showSidebar === 'Historical events',
        },
        {
            key: 'absolutemethod',
            icon: <FaExclamation style={{ color: showSidebar === 'Absolute' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Absolute',
            preventHover: showSidebar === 'Absolute',
        },
        {
            key: 'outliersmethod',
            icon: <FaDotCircle style={{ color: showSidebar === 'Outliers' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Outliers',
            preventHover: showSidebar === 'Outliers',
        },
        isBacktestLicense() ? {
            key: 'ainews',
            icon: <FaNewspaper style={{ color: showSidebar === 'AI news searcher' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'AI news searcher',
            preventHover: showSidebar === 'AI news searcher',
        } : null,
        {
            key: 'stoplosstakeprofit',
            icon: <FaStop style={{ color: showSidebar === 'Stop loss and take profit' ? selectColor : undefined }} size={35} />,
            width: 200,
            onClick: (type: SidebarType) => handleSidebarClick(type),
            text: 'Stop loss and take profit',
            preventHover: showSidebar === 'Stop loss and take profit',
        },
    ];

    sidebarContent = sidebarContent.filter(x => x != null) as SidebarItemType[];

    return <Box
        justifyContent={'center'} alignItems={'center'}
        transition="3s ease"
        bg={useColorModeValue('gray.300', '#292C31')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 'full' }}
        h="full">
        {sidebarContent.filter(x => !showThis || showThis && showThis.includes(x.text)).map((item) => (
            <>
                <NavItem key={item.key} icon={item.icon as any} width={item.width} preventHover={item.preventHover} onClick={() => {
                    item.onClick(item.text);
                } }>
                    <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingLeft={5}>
                        {item.text}
                    </Heading>
                </NavItem>
                <Divider />
            </>
        ))}
    </Box>;
}

export function RenderSidebarContent({ showSidebar, timeSeriesMenu, isScreening }: {showSidebar: SidebarType | null, timeSeriesMenu: MenuCategory[], isScreening?: boolean}) {
    return <Container p={3}>
        <LazyLoading>
            <>
                {showSidebar === "Sample" &&
        <>
            <SelectionFilters timeSeriesMenu={timeSeriesMenu} />
        </>}
                {showSidebar === "Screening Analysis" &&
        <>
            <ToppLists timeSeriesMenu={timeSeriesMenu} isScreening={isScreening} />
        </>}
                {showSidebar === "Growth" && <Growth />}
                {showSidebar === "Years/Months/Days" && <DateSelector />}
                {showSidebar === "Cointegration methods" && <Relative />}
                {showSidebar === "Absolute" && <Absolute />}
                {showSidebar === "Historical events" && <HistoricalEvents />}
                {showSidebar === "AI news searcher" && <AiNewsMethod />}
                {showSidebar === "Outliers" && <Outliers />}
                {showSidebar === "Stop loss and take profit" && <StopLossTakeProfit />}
            </>
        </LazyLoading>
    </Container>;
}