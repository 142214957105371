import mongoose, { Schema } from "mongoose";
import { TransformationTypeKey } from "../../../../transformations/transformations-dtos";
import methods from "../../frontend/methods";
import timeserieshelpervalues from "./timeserieshelpervalues";

export interface TimeSeriesDocument extends Document {
    _id: string;
    collectionInDb: string;
    field: string;
    column: string;
    date: string;
    ID: string;
    transformationKey: string;
    transformations: TransformationTypeKey[];
    timeSeriesKey: string;
    transformationId: string;
    graphValue: { type: Schema.Types.ObjectId; ref: "timeserieshelpervalues" }[];
    title: string;
    display: string;
    active: boolean;
    periodicity: string;
    frontendId: string;
    parameters: Schema.Types.Mixed;
    category: string;
    categorizeType: { type: Schema.Types.Mixed; default: null };
    normalization: { type: Schema.Types.Mixed; default: "none" };
    disaggregationMethod: { type: string; default: "previous-value" };
    clientId: { type: Schema.Types.ObjectId; ref: "clients" };
    main: { type: boolean; default: false };
    stationary: { type: boolean; default: false };
    multiply: { type: number; default: null };
    timeseries: { type: Schema.Types.ObjectId; ref: "timeseries" }[];
}

const timeseriesSchema = new Schema({
    collectionInDb: String,
    field: String,
    column: String,
    date: String,
    ID: String,
    transformationKey: String,
    transformations: [ String ],
    timeSeriesKey: String,
    transformationId: String,
    graphValue: [ { type: Schema.Types.ObjectId, ref: "timeserieshelpervalues" } ],
    title: String,
    display: String,
    active: Boolean,
    periodicity: String,
    frontendId: String,
    parameters: Schema.Types.Mixed,
    category: String,
    categorizeType: { type: Schema.Types.Mixed, default: null },
    normalization: { type: Schema.Types.Mixed, default: false },
    disaggregationMethod: { type: String, default: "previous-value" },
    clientId: { type: Schema.Types.ObjectId, ref: "clients" },
    main: { type: Boolean, default: false },
    stationary: { type: Boolean, default: false },
    multiply: { type: Number, default: null },
    timeseries: [ { type: Schema.Types.ObjectId, ref: "timeseries" } ]
});

// eslint-disable-next-line @typescript-eslint/space-before-function-paren
timeseriesSchema.pre("findOneAndDelete", async function(next) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const timeseriesId = this["_conditions"]._id;
    await methods.updateMany({ timeseries: timeseriesId }, { $pull: { timeseries: timeseriesId } });
    const timeSeries = await timeseries.findOne({ _id: timeseriesId });
    if (!timeSeries) {
        return next();
    }
    const graphValuesIds = timeSeries.graphValue;
    await timeserieshelpervalues.deleteMany({ _id: { $in: graphValuesIds } });
    const theTimeSeries = await timeseries.findOne({ _id: timeseriesId });
    await timeseries.deleteMany({ _id: { $in: theTimeSeries.timeseries } });
    const timeserieshelpervaluesId = theTimeSeries.graphValue.concat(timeSeries.graphValue);
    await timeserieshelpervalues.deleteMany({ _id: { $in: timeserieshelpervaluesId.flat() } });
    next();
});

export const timeseries = mongoose.model<TimeSeriesDocument>("timeseries", timeseriesSchema);

const marketCap = new Schema({}, { strict: false });
export const marketcap = mongoose.model("historicalmarketcapitalizations", marketCap);
